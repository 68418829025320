<template>
  <Modal
      v-model="portraitStatus"
      title="单题画像"
      :footer-hide="true"
      width="1000"
      @on-cancel="close"
  >
    <div class="category-detail" v-if="info.data">
      <div class="category-detail-tit">试题简介
        <Tooltip max-width="500">
          <Icon type="ios-help-circle-outline" :size="18" />
          <div slot="content"  style="white-space: normal;">
            <p>推荐指数：</p>
            <p>星级体现题目的准确度，以下为星级标准：</p>
            <p>1、优秀题目标准（5星）：</p>
            <p>a、答案明确且准确（无疑问，无纠结）。</p>
            <p>b、题目完整（题干、选项、解析）。</p>
            <p>c、 知识点恰当、标签覆盖度完整 。</p>
            <p>d、解析由  “官方链接+文字描述”  组成，针对各选项有具体的文字解答。</p>
            <p>e、由专业老师校对的题。</p>
            <p style="margin-top: 5px">2、良好题目标准（4星）：</p>
            <p>a、答案明确且准确（无疑问，无纠结）。</p>
            <p>b、题目完整（题干、选项、解析）。</p>
            <p>c、解析由  “官方链接+文字描述”  组成，针对各选项有具体的文字解答。</p>
            <p style="margin-top: 5px">3、一般题目标准（3星）：</p>
            <p>a、答案明确且准确（无疑问，无纠结）。</p>
            <p>b、题目完整（题干、选项、解析）。</p>
            <p>c、解析描述（没有官方链接）。</p>
            <p style="margin-top: 5px">4、三星以下(不包含三星)仅供参考。</p>
          </div>
        </Tooltip>
      </div>
      <Row class="mb">
        <Col span="18">
          <div class="category-detail-info">
            <p class="tit">所在题库：</p>
            <div><p v-for="(item,index) in info.category_list" :key="item.id">{{item.name}}<span v-if="index < info.category_list.length - 1">-</span></p></div>
          </div>
        </Col>
      </Row>
      <Row class="mb">
        <Col span="6">
          <div class="category-detail-info">
            <p class="tit">试题ID：</p>
            <p>{{ info.data.id }}</p>
          </div>
        </Col>
        <Col span="6">
          <div class="category-detail-info">
            <p class="tit">题型：</p>
            <p>{{ info.data_types[info.data.type] }}</p>
          </div>
        </Col>
        <Col span="6">
          <div class="category-detail-info">
            <p class="tit">难度等级：</p>
            <p>{{ info.data_levels[info.data.level] }}</p>
          </div>
        </Col>
      </Row>
      <Row class="mb">
        <Col span="6">
          <div class="category-detail-info">
            <p class="tit">总作答数：</p>
            <p>{{ info.data.answer_count }}</p>
          </div>
        </Col>
        <Col span="6">
          <div class="category-detail-info">
            <p class="tit">答对数：</p>
            <p>{{info.data.right_count}}</p>
          </div>
        </Col>
        <Col span="6">
          <div class="category-detail-info">
            <p class="tit">答错数：</p>
            <p>{{ info.data.answer_count == 0 ? 0 : info.data.answer_count - info.data.right_count }}</p>
          </div>
        </Col>
        <Col span="6">
          <div class="category-detail-info">
            <p class="tit">作答正确率：</p>
            <p>{{ info.data.right_rate }}</p>
          </div>
        </Col>
      </Row>
      <Row class="mb">
        <Col span="6">
          <div class="category-detail-info">
            <p class="tit">创建时间：</p>
            <p>{{info.data.created_at}}</p>
          </div>
        </Col>
        <Col span="6">
          <div class="category-detail-info">
            <p class="tit">更新时间：</p>
            <p>{{info.data.updated_at}}</p>
          </div>
        </Col>
        <Col span="12">
          <div class="category-detail-info">
            <div class="tit">
              <p style="margin: 3px 0 0 5px">推荐指数：</p>
            </div>
            <p>
              <Rate allow-half show-text v-model="info.data.star" disabled >
                <span style="color: #f5a623">{{ info.data.star }}</span>
              </Rate>
            </p>
          </div>
        </Col>
      </Row>
<!--      <div class="category-detail-tit mt30">包含知识点（{{ info.nodes.length }}）</div>-->
<!--      <Row class="mb">-->
<!--        <Col span="24">-->
<!--          <div class="category-detail-cont">-->
<!--            <div v-for="item in info.nodes" :key="item.node_id">-->
<!--              <Tooltip>-->
<!--                <p class="category-detail-cont-name">{{ item.node.name }}</p>-->
<!--                <div slot="content" style="white-space: normal;">-->
<!--                  {{ item.node.name }}-->
<!--                </div>-->
<!--              </Tooltip>-->
<!--            </div>-->
<!--          </div>-->
<!--        </Col>-->
<!--      </Row>-->
<!--      <div class="category-detail-tit mt30">包含标签（{{ info.labels.length }}）</div>-->
<!--      <Row class="mb">-->
<!--        <Col span="24">-->
<!--          <div class="category-detail-cont">-->
<!--            <div v-for="item in info.labels" :key="item.label_id">-->
<!--              <Tooltip>-->
<!--                <p class="category-detail-cont-name">{{ item.label.name }}</p>-->
<!--                <div slot="content" style="white-space: normal;">-->
<!--                  {{ item.label.name }}-->
<!--                </div>-->
<!--              </Tooltip>-->
<!--            </div>-->
<!--          </div>-->
<!--        </Col>-->
<!--      </Row>-->
    </div>
  </Modal>
</template>

<script>
import util from '@/utils/tools.js';
export default {
  name: "dataPortrait.vue",
  data(){
    return{
      info:{},
      // modalStatus:true
    }
  },
  props:{
    dataId:{
      type:String,
      default:''
    },
    portraitStatus:{
      type:Boolean,
      default:false
    }
  },
  watch:{
    dataId(newV){
      if(newV){
        this.getInfo();
      }
    }
  },
  created(){
    // this.categoryId = this.$route.query.id;
    this.getInfo();
  },
  methods:{
    close(){
      console.log('+++++++')
      this.$emit('close');
    },
    getInfo(){
      let params = {
        action:'dataProfile',
        data_id:this.dataId,
      };
      this.api.dataset.trainExecute(params).then((res)=>{
        this.info = res;
        this.info.data.created_at = util.timeFormatter(
            new Date(+this.info.data.created_at * 1000),
            'yyyy-MM-dd hh:mm'
        );
        this.info.data.updated_at = this.info.data.updated_at == '0' ? this.info.data.created_at : util.timeFormatter(
            new Date(+this.info.data.updated_at * 1000),
            'yyyy-MM-dd hh:mm'
        );
        this.info.data.star = Number(this.info.data.star);
      })
    }
  }
}
</script>

<style scoped lang="scss">
.category-detail{
  font-size: 14px;
  padding-bottom: 40px;
  //height: 500px;
  overflow-y: auto;
  .category-detail-tit{
    margin-bottom: 10px;
    font-size:18px;
    font-weight: bold;
  }
  .category-detail-info{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    .tit{
      width: 100px;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      word-break: break-word;
    }
    >div{
      display: flex;
      justify-content: flex-start;
      align-items: center;
    }
    >p{
      height: 32px;
      line-height: 32px;
    }
  }
  .category-detail-cont{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    >div{
      margin:0 10px 10px 0;
      width: 150px;
      padding: 0 10px;
      height: 40px;
      border: 1px solid #dcdee2;
      border-radius: 4px;
      line-height: 40px;
      display: flex;
      justify-content: center;
      >p:nth-child(1),
      .category-detail-cont-name{
        margin-right: 10px;
        width: 110px;
        text-align: center;
        white-space: nowrap;
        overflow:hidden;
        text-overflow:ellipsis;
      }
    }
  }
  .mb{
    margin-bottom: 10px;
  }
  .mt30{
    margin-top: 30px;
  }
}
</style>
